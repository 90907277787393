html,
body {
  height: 100%;

}
body {
  display: flex;
  flex-direction: column;
  overscroll-behavior: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


html {
  scroll-behavior: smooth;
}

/* //hide scrollbar// */
* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

:root {
  font-size: 62.5%;
}
/* font-family: gelica, sans-serif; */

/* font-family: neue-haas-grotesk-display, sans-serif; */

/* font-family: neue-haas-grotesk-text, sans-serif; */





.AppContainer {
  width: 100vw;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  text-align: center;
  background: #FAF9F8;
  overflow-y: scroll;
  content: '';
  display: flex;
  flex-direction: column;
  left: 0;
  bottom: 0;
  position: relative;
  z-index: 10;
  min-height: 100vh;
}
.HomeButtonContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


ul {
  padding: 0;
  list-style-type: none;
}
.TopContainer {
  margin:auto;
  margin-top: 24rem;
}
.main_type {
  font-size: 23rem;
  font-family: neue-haas-grotesk-text, sans-serif;
  text-transform: lowercase;
  font-weight:600;
  letter-spacing: -.05em;
  line-height: .85;
  text-align: left;
  margin-left: -1rem
}

h5,.leadin {
  font-size: 1.9rem;
  color:white;
  text-transform: initial;
  letter-spacing: .1rem;
  font-weight: 400;
   font-family: "neue-haas-grotesk-display", sans-serif;;
   text-align: left;
   line-height: 1.6;
}

h6 {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: left;
  line-height: 1.5
}

.BottomContainer {
  margin: auto;
  margin-top: 6rem;
  margin-bottom: 6rem;
  width: 95rem

}

.Button_website {
  font-family: neue-haas-grotesk-text, sans-serif;
  background-color: black;
  color: white;
  padding: .5rem 2rem;
  width: fit-content;
  border-radius: 10rem;
  font-weight: 600;
  height: 4rem;
  justify-content: center;
  display: flex;
  align-items: center;
  line-height: 1.5
;
}

.Button_website:hover {
  box-shadow: 5px 5px 10px rgba(36, 36, 36, 0.5);
  color:bisque

}
li {
  list-style: '•';
  color: white;
  font-size: 2rem;
  padding-left: 1rem
}

button.magnifier {
  cursor: pointer;
}
button {
  background-color: #FAF9F8;
  background: none;
}


/* ///// Media Queries ///// */
@media screen and (min-width: 769px) and (max-width: 1030px) {
  .main_type {
    font-size: 20rem;
  }

.Button_website {
  font-size: 1rem;
    line-height: 1.5;
}
h5 {
  font-size: 1.7rem;
}
h6 {
  font-size: 1rem;
}
}


@media screen and (min-width: 480px) and (max-width: 768px) {
  .main_type {
    font-size: 17rem;
  }

button.Button_website {
  font-size: 1rem;
  height: 4rem
}
h2 {
  font-size: 3.6rem;
}
h5 {
  font-size: 1.6rem;
}
}

@media screen and (max-width: 479px) {
  .main_type {
    font-size: 12.75rem;
  }
  .TopContainer {
    margin-top:15rem
  }
  button.Button_website {
    font-size: 1rem;
    height: 3.75rem
  }
  h2 {
    font-size: 3.75rem;
    line-height: 1.2;
   }
   h5, .leadin {
    font-size: 1.5rem;
   }
   .h5.marginTop {
    margin-top: 3rem;
}
}






