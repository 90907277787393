#NavbarContainer {
  padding-top: 5rem;
  width: 100%;
  position: fixed;
  height: 13.5rem;
  top: 3%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background: white;
  margin-bottom: 2rem;
  border: 0.5px solid black;
  padding-left: 10%;
  padding-right: 10%

}
.NavbarSection {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 110rem;
  margin: auto;
}

.Navbar-items {
  display: flex;
  padding-left: 0
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  color: #000;
text-align: center;
font-family: neue-haas-grotesk-text, sans-serif;
font-size: 1.1rem;
font-style: normal;
font-weight: 400;
line-height: 3.5rem;
letter-spacing: .35rem;
text-transform: uppercase;
text-decoration-style: none;
}
.Navbar-logo {
  margin-top: 1.5rem
}


.nav-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}


a {
  text-decoration: none !important;
}

.logo {
width: 45px;
}

.nav-item {
padding: 20px;
display: flex;
flex-direction: column;
align-items: center
}

.navActive {
  width: 1.1rem;
  visibility: hidden;
}

.nav-links.active .navActive {
  visibility: visible;
}


.nav-link.active .icon {
  visibility: visible; /* Visible when the link is active */
}



li:first-child {
padding-left: 0;

}
.active {
  font-weight: 600;
  letter-spacing: .34rem;
}


a.nav-links {
  color: rgba(0,0,0,.5);
  font-weight: 500;
}

a.nav-links:hover {
  color: rgba(0,0,0,.9);
}

a.nav-links.active {
  font-weight: 700;
  color: black
}








/* ///// Media Queries ///// */
@media screen and (min-width: 768px) and (max-width: 1030px) {

#NavbarContainer {
  padding-left: 5rem;
  padding-right: 5rem
}
.nav-item {
  padding:  2rem 1.5rem
}

}
