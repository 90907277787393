/* General reset for the page */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Main container for the carousel */
.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem
}

.prev-button {
background:  #231F20;
margin-right: -3rem

}

 .next-button {
  background:  #231F20;
  margin-left: -3rem

  }


img.carousel-image {
  width: 95rem
}

.arrow {
  width: 4rem;
  position: relative;
  z-index: 50;
  background-color:  #231F20;
  margin-bottom:14rem
}


/* ///// Media Queries ///// */
@media screen and (min-width: 769px) and (max-width: 1030px) {
  img.carousel-image.ssc {
    width: 75rem
  }
  }

  @media screen and (max-width:768px) {
    img.carousel-image.ssc {
      width: 100%
    }
  }
