.CardContainer {
  border: .75px solid #000;
  display: flex;
  padding: 2rem;
  width: 46.5%;
  height: 55rem;
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  cursor: pointer;

}
.CardContainer.fh {
  width: 100%;
  height: 48rem
}
.Card {
  display: flex;
  height: 47.5rem;
  width:100%
}
.Card.fh {
  display: flex;
  height: 100%;
  width:100%;
  margin-bottom: 2.5rem;
}
.CardOpener {
  font-family: gelica, sans-serif;
  font-size: 2.1rem;
  text-transform:initial;
  letter-spacing: .02rem;
  line-height: 1.5;
  text-align: left;
  font-weight: 300;
  margin-bottom: 1.9rem;

}
h6 {
  font-weight: 600;
  font-size: 1.2rem;
  text-align: left;
  line-height: 1.5
}
p {
  font-family: "neue-haas-grotesk-display", sans-serif;
  ;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: left;
  text-transform:initial;
  letter-spacing: .1rem;
}
p.CardDescription.nyt {
  letter-spacing: .04rem;
}
.CardText {
  display:flex;
  flex-direction: column;
  width: 50%;
  margin-right: 1.5rem;
  height:fit-content
}
.CardText.fh {
  padding-left: 1.5rem
}
.CardImage {
  width: 50%;
  margin-bottom:3rem;
  display: flex
  ;
      flex-direction: column;
      align-items: center;
  }

.CardImage.fh {
  width: 65%;
  display: flex
  ;
      flex-direction: column;
      align-items: center;
}
.IndiCard {
  width: 100%;
  margin-bottom: 1rem;

}
.IndiCard_two{
  margin-left: -1rem;
  width: 21%;
  margin-top: -.25rem;
  align-self: center;
}


.IndiCard.fh {
  margin-bottom: .5rem;

}
.CardContainer.mobile:hover  {
  padding: 1.9rem 1.9rem 0 1.9rem;
}
.CardContainer:hover {
border: 1.5px solid rgba(0, 0, 0, 0.30);
background: #FFF;
box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
padding: 1.9rem
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  font-weight: 600;
  border-top: .5px solid #000;
  padding: 2.5rem
}
.footer.nyt {
  letter-spacing: .3rem;
}



/* ///// Media Queries ///// */
@media screen and (max-width: 1030px) {
  /* @media screen and (min-width: 768px) and (max-width: 1030px) { */



.CardOpener {
  font-size: 2rem;
}

.footer.nyt {
  letter-spacing: .25rem;
  font-size: 1.05rem;
}
.CardContainer {
  width: 100%;
  margin-bottom: 5rem;
  padding: 2rem 2rem 0 2rem;
  height: fit-content
}

.CardContainer:hover  {
  padding: 1.9rem 1.9rem 0 1.9rem;
}
.CardContainer.mobile {
  height: fit-content;
  margin-bottom: 0;
}

.IndiCard.desktop {
  width: 115%;
  margin-top: 1rem;
  margin-bottom: 2rem;

}
.CardImage.mobile {
  margin-bottom: 0;
}
.CardImage.mobile, .Card {
  height: fit-content
}
.CardText.mobile {
  margin-right: 3rem
}
p.CardDescription.nyt {
  letter-spacing: .1rem;
}
}



@media screen and (max-width: 479px) {

  .footer {
    margin-top: 2rem;
    line-height: 1.8;
  }

  .CardOpener{
    font-size: 1.9rem;
  }
  .CardOpener.fh {
    letter-spacing: -0.02rem;
  }
  .CardImage {
    margin-bottom: 0
  }

}

