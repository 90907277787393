#navbar-mobile {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  border: 0.5px solid black;
  background: white

}


a #navbar-mobile  {
  color: rgba(0,0,0,.5);
}
.logo {
  width: 45px;
  }
  .Navbar-logo {
    margin-top: 1.5rem
  }

  * {
    margin: 0;
    padding: 0;
    border: 0;
    color: #000;
  text-align: center;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: .35rem;
  text-transform: uppercase;
  text-decoration-style: none;
  }

li:first-child {
  padding-left: 20px;
}
.navbar-mobile-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-item_mobile{
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center
}
.navbar-spacer {
  width: 6.5rem
}
.navbar-logo.mobile {
  margin-top: 5rem
}

ul.navbar-mobile {
  padding: 0
}
.nav-item.mobile {
  padding: 2rem
}
.nav-item.mobile.top {
  margin-top:20%
}
.navbar-mobile.active {
  display: flex;
  height: 100vh;
  background:#FAF9F8;
  flex-direction: column;
  padding-top: 15%;
  border-top: .5px solid black
  }

  .navbar-mobile.not-active {
    display: none
  }
  .navActive_visable {
    width: 1.1rem;
    visibility: visible;
  }
  .navActive_visable.hidden {
    visibility: hidden
  }

  .hover_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hover_item.hover {
    font-weight: 600;
  }

