.AboutContainer {
  padding-left: 10%;
    padding-right: 10%;
    max-width: 120rem;
    margin: auto;
    display: flex;
   flex-direction: column;
   margin-top: 1.5rem;
   margin-bottom: 6rem;
   width: 50%
}
h4 {
color: #000;
font-family: "Neue Haas Grotesk Display Pro";
font-size: 2rem;
font-style: normal;
font-weight: 400;
line-height: 1.75;
letter-spacing: 1.75px;
text-transform:initial;
}
.AboutImages {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  width:90%;
  min-width: 450px
}
.AboutImage {
width: 90%;
/* margin: 0 1rem; */
align-self: center;
}


.AboutHi {
  font-size: 5.5rem;
  text-transform: lowercase;
  font-weight:600;
  letter-spacing: -.03em;
  line-height: 1.1;
  color: #000;
  text-align: right;
  margin-bottom: 0;
  margin-top: 14.5rem;
  width: 20rem;
  margin-right:1.75rem;
  margin-left: 1.75rem;
  text-align: right;
  height: fit-content;
}

.AboutText {
  text-align: left;
  margin-top: 2rem;
font-family: gelica, sans-serif;
font-weight: 300;
letter-spacing: .01rem;
overflow-y: scroll;
font-weight: 300
}
.AboutInfo {
  display: flex;
   justify-content: left;
   height: 5rem;
}
.AboutResume {
  font-weight: 600;
  font-size: 1rem;
  padding-top: 1rem;
  letter-spacing: .34rem;
  text-align: left;
}
.AboutResume:hover {
  font-weight: 300;
  letter-spacing: .35rem;
}
.AboutResume.download {
  margin-left: 1rem;
}
.AboutResume.view {
  margin-right: 1rem
}
.AboutResume_span {
  margin-left: 1.5rem;
}

.Container.about {
  display: flex;
width: 95rem;
margin: auto;
margin-top: 29rem;
height: 60rem
}

.AboutContainerNew {
  width: 60%;
  margin-bottom: 6rem;
  overflow-y: scroll;
display: flex;
flex-direction: column;
margin-bottom: 4rem;
margin-left: 2.5rem
}
.AboutResume_span {
  visibility: visible;
}

/* ///// Media Queries ///// */
@media screen and (min-width: 769px) and (max-width: 1030px) {
  .Container.about {
    width: 90%
  }
  .AboutHi{
    font-size:4.75rem;
    margin-top:12rem
  }
  .AboutImages {
    min-width: 400px
  }

}

@media screen and (max-width: 768px)  {
  .AboutInfo {
    flex-direction: column;
    height:fit-content;
  }

  .AboutResume.download {
    margin: 0
  }
  .AboutResume.view {
    margin-bottom: -1.75rem
  }
  .AboutResume_span {
    visibility: collapse;
  }

}

@media screen and (min-width: 480px) and (max-width: 768px)  {
.Container.about {
  width: 90%
}
.AboutHi{
  font-size: 4rem;
  margin-top: 9.75rem;
  width: 32%;
  margin-right:2rem
 }
 .AboutImages {
  min-width: 100%
}
.AboutContainerNew {
  margin-left:2rem
}
.AboutText {
  font-size: 1.9rem
}

}


@media screen and (max-width: 479px) {

  .Container.about.mobile {
    margin-top: 18rem;
    width: 90%;
    flex-direction: column;
    height: 65rem

  }
  .AboutHi {
    font-size: 4rem;
    margin-top: 0;
    width: 100%;
    margin: 0;
    text-align: left;
  }
  .AboutContainerNew {
    margin: 0;
    width: 100%;
    margin-top:2rem
  }
  .AboutImages {
    min-width: 100%
  }
  .AboutText {
    font-size: 1.9rem
  }


}
