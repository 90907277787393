.DevImagesContainer {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 6rem;

}

.DevImage {
  width: 100%;
}

.DevImage.wd {
  border: .5px solid black

}

/* ///// Media Queries ///// */

@media screen and (min-width: 769px) and (max-width: 1030px) {
  .DevImagesContainer {
    gap:5rem
  }
  }

  @media screen and (min-width: 480px) and (max-width: 768px) {
    .DevImagesContainer {
      gap:3.5rem
    }
    }

    @media screen and (max-width:479px) {
      .DevImagesContainer {
        display: flex;
        flex-direction: column;
        gap:4rem;
        width: 90%
      }

      .BottomContainer.dev {
        margin-top:4rem;
      }

    }
