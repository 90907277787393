.InnerContainer {
  display: flex;
  margin: auto;
  width: 95rem;
  flex-direction: column;
}

h2 {
  font-family: gelica, sans-serif;
  color: white;
  font-size: 4.5rem;
  text-transform: initial;
  letter-spacing: .01rem;
  line-height: 1.5;
  font-weight: 300;

}
.h2_white {
  color: black
}
h5,.leadin {
  font-size: 1.9rem;
  color:white;
  text-transform: initial;
  letter-spacing: .1rem;
  font-weight: 400;
   font-family: "neue-haas-grotesk-display", sans-serif;;
   text-align: left;
   line-height: 1.6;
}
.h5.openerType {
  text-align: center;
}
.h5.black {
  color: black;
}
.h5.black.center {
  text-align: left;
  margin-bottom: 3rem;
}

.h5.black.center.venn {
  margin-top: 2rem;
}

.h5.small {
  color: black;
  font-size: 1.6rem;
  line-height: 1.5;
}

.h5.dropCap, .h5.marginTop {
  margin-top:5rem;
}

.h6.small {
  margin-bottom: .5rem
}

li.smHed {
  margin-left:-3rem
}


.divider_curved {
  width: 100%;
  height: 3rem;
  border-radius: 20px;
border-bottom: .5px solid #000;
margin-bottom: 2rem;}

.divider_curved.top {
  margin-bottom: 0rem
}

.divider_curved_down {
  width: 100%;
  height: 3rem;
  border-radius: 20px;
  border-top: 1px solid #000;
}

.BlackHeds {
  background: black;
  padding: 2rem;
  width: fit-content;
  height:fit-content;
  margin: auto;
  margin-bottom: .5px;
  border-radius: 20px;
  width: fit-content;
  height: fit-content;
  line-height: 0;
  padding: 1rem 1.5rem;

}
.BlackHeds.white {
  background: white;
  border: 1.5px solid black;
}


.h5.dropCap::first-letter {
  font-family: gelica, sans-serif;
  color: white;
  font-size: 10.5rem;
  float: left;
  line-height: .85; /* from 1 */
  margin-right: 0.05em;
  font-weight: 200;
}

.h5.dropcap.black {
  color: black
}
.h5.dropCap.black::first-letter {
  color: black
}
.h5.leadins {
  margin-top: 6rem;
  margin-bottom: 5rem
}
.leadin {
  font-weight: 600;
  font-size: 1.75rem;
  letter-spacing: .05rem;
}
.leadin.black {
  color: black;

}

.StudiesTopContainer {
  padding-top: 5rem;
}
.ButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  position: relative;
  margin-top: 3rem
}


.Button.enlarge {
  background: black;
  color: white;
  padding: .5rem 2rem;
  border-radius: 10rem;
  width: fit-content;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  border: 1.25px solid rgb(160, 157, 157);
  font-size: 1.3rem;
  font-weight: 600;
  position: relative;
  z-index: 50;
}
.Button.enlarge.white {
  background: white;
  color:black;
  border: 1.25px solid black

}

.magnifier {
  width: 6rem;
  position: relative;
  z-index: 50;
  ;
}
.magnifier.wh {
  background-color: #FAF9F8;
}

button {
  background-color: #FAF9F8;
  background: none;
}
.competitive{
  width: 75rem;
  border-radius: 20px;
  margin: auto;
  margin-top: -11.5rem;
}


.competitive_lg {
  border-radius: 20px;
  width: 100%;
  height: fit-content;
}

.competitive.fh_mobile {
  width:95rem;
  margin-top: -1rem
}

.h5.bullets {
  margin-top: 2rem
}

li {
  list-style: '•';
  color: white;
  font-size: 2rem;
  padding-left: 1rem
}
li.black {
  color: black
}

li.first_li_research {
  padding-left: 1rem
}
li.first_li_testing {
  padding-left: 1rem;
  margin-left: 7rem
}
li.second_li_testing {
  margin-left: -4rem
}

ul {
  padding-left: .75rem
}

.space {
  padding-left: 1rem
}

.dropCap.hang {
  font-family: gelica, sans-serif;
  font-weight: 200;
  color: black;
  font-size: 5.5rem;
  float: left;
  margin-top: .75rem;
  font-weight: 200;
}
.dropCap.hang.one {
  margin-left:1rem
}
.dropCap.nyt_test {
  font-size: 5.4rem
}

.smallWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: 1rem
}


@media screen and (min-width: 769px) and (max-width: 1030px) {


   .InnerContainer{
    width: 90%;
    margin-bottom:7rem
  }
  .InnerContainer {
    margin-bottom: 0;
  }


  .Button.enlarge, .Button.enlarge.white {
    font-size: 1.15rem;
  }
  h2.cases_openerText {
    font-size: 3.9rem
  }
  h5 {
    font-size: 1.7rem;
  }

  h6 {
    font-size: 1rem;
  }


  .h5.dropCap::first-letter {
    font-size: 9.25rem;
  }
  .competitive{
    width: 80%
  }


}


/* ///// Media Queries ///// */
@media screen and (min-width: 480px) and (max-width: 768px)  {


  h2 {
    font-size: 3.6rem;
  }
  h5 {
    font-size: 1.6rem;
  }
  .h5.small {
    font-size: 1.4rem;
  }

  h6.h6.small {
    font-size: 1rem;
  }
  .InnerContainer {
    width: 90%
  }

  .h5.dropCap::first-letter {
    font-size: 8.5rem;
    float: left;
    line-height: .9; /* from 1 */
    margin-right: 0.05em;
    font-weight: 200;
  }
  .Button.enlarge {
    font-size: 1.1rem;
    line-height: 1.5
  }

  .competitive  {
    width: 100%
  }
 }

 @media screen and (max-width: 479px) {


 .Container.studies {
  margin-top: 15rem
 }

 h2 {
  font-size: 3.75rem;
  line-height: 1.2;
 }
 h5, .leadin {
  font-size: 1.5rem;
 }

.h6.small {
  font-size: 1rem;;
}
.h5.small {
  font-size: 1.3rem;
}
.InnerContainer {
  width: 85%
}
.h5.dropCap::first-letter {
  font-size: 8rem
}
.Button.enlarge {
  font-size: 1.1rem;
  line-height: 1.6;
}
.competitive  {
    width: 100%
  }

  .h5.dropCap {
    margin-top: 3rem;
  }


  .ButtonContainer {
    margin-top:1rem;
    margin-bottom: 2rem;
  }

.h5.marginTop {
  margin-top:4rem
}

.h5.dropCap.black {
  margin-top:1.5rem
}
.h5.dropCap.black.too {
  margin-top: 3.5rem
}

.ButtonContainer {
  margin-bottom: 0
}

 }
