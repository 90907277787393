.PrintImages.fitness, .WebImages {
  padding-right: 6rem
}
.WebImages.hb {
  padding-right: 0;
}

.PrintImage, .WebImage {
max-width: 100%;
max-height: 100%;
display: inline-flex;
}


.PrintImage.responsive.fitness {
  border: .5px solid black;

}

.WebImage.hb {
  margin-right: 0px
}
.PrintImages, .WebImages {
  display: flex;
  flex-direction: column;}

.PrintChildContainer, .WebChildContainer {
  display: flex;
  justify-content: space-between;
  width: 100%
}

.WebChildContainer {
  margin-top: 5rem
}

.Print_captions {
  font-weight: 600;
  margin-top: 2rem;
  line-height: 2;

}


.Print_captions.no_margin {
  margin-right: 0
}



/* ///// Media Queries ///// */
@media screen and (min-width: 769px) and (max-width: 1030px) {


  .PrintChildContainer, .WebChildContainer {
    display: flex;
   justify-content: space-between;
  }



.PrintImage.fit {
  margin-right: 0
}
.PrintImages.fitness, .WebImages {
padding-right: 4rem

}

  }


  /* ///// Media Queries ///// */


@media screen and (min-width: 480px) and (max-width: 768px){
  .PrintImagesContainer{

  width:fit-content

  }
  .PrintImages.fitness, .WebImages {
    /* padding-right: 6% */
    /* padding-right: 3.25rem */
    padding-right: 0;

  }
  .PrintImages {
    width: 63%
    /* width: 64% */

  }
  .PrintImages.fitness {
    width:31.5%
    /* width:38% */
  }

  .WebChildContainer {
    margin-top: 2.5rem
  }

  .WebImages.phone {
    width: 24%
    /* width: 30% */

  }

  .WebImages {
    width: 34%
    /* width: 40% */

  }
  .WebImages.hb {
    width: 34%
  }
  .Print_captions {
    margin-top: 1.5rem;

  }
}


@media screen and (max-width: 479px) {
  .PrintChildContainer, .WebChildContainer {
    flex-direction: column;
    width: 90%
  }
  .PrintImages.fitness, .WebImages {
    padding-right: 0;
    margin-bottom: 2rem
  }

  .Print_captions {
    margin-top: 1rem;

  }
  .WebChildContainer {
    margin-top: 2rem;
    width: 60%
  }


  .WebImages.hb {
    margin-bottom: 0
  }


}
