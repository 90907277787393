
.HomeButton {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-size: 5.5rem;
  text-transform: lowercase;
  font-weight:600;
  letter-spacing: -.03em;
  line-height: 1.1;
  margin-left: 4.3rem;
  /* color: rgba(71, 71, 69, 0.5); */
  color: rgba(0, 0, 0, 0.5);
  background-color: inherit;
  cursor:pointer;
}

.HomeButton.ux{
  margin-left: 0;
  color: #000;
  margin-bottom: .95rem;
}

.HomeButton:hover {
  /* color: rgb(256, 228, 195); */
  color: rgba(0, 0, 0, 0.3);


}
.HomeButton.ux:hover {
  /* color: rgba(256, 228, 195, 0.5) */
  color: rgba(0, 0, 0, 0.3);

  /* color: #808080; */

}
.HomeButton.ux.default {
  color: #808080;
  margin-bottom: .05rem;
}

a.HomeButton.ux.default > span.smallSlash  {
  color: #808080}

  .HomeButton.ux.default:hover {
    color: rgba(0, 0, 0, 0.3);

  }

  a.HomeButton.ux.default:hover > span.smallSlash  {
    color: rgba(0, 0, 0, 0.3)}

    .HomeButton.print {
      color: #000;
    cursor: default
    }


hr {
  display: block;
  height: 3px;
  border: 0;
  border-top: 3px solid #000;
  margin: -.65rem 0;
  padding: 0;
}
.smallSlash {
  font-size: 4.5rem;
  margin: 0 -.6rem 0 0;
  font-weight: 600;
}

  a.HomeButton.ux:hover > span.smallSlash  {
    border-color:  rgba(256, 228, 195, 0.5)
  }

    .HomeButton.ux:hover .under {
      border-color: rgba(256, 228, 195, 0.5)
    }



    /* ///// Media Queries ///// */
@media screen and (min-width: 769px) and (max-width: 1030px) {

  .HomeButton {
    font-size: 4.75rem
  }
  }
  @media screen and (min-width: 480px) and (max-width: 768px) {

    .HomeButton {
      font-size: 4rem;
      margin-left: 4.3rem;
    }
    .smallSlash {
      font-size: 3.5rem
      }
    }


    @media screen and (max-width: 479px) {

      .HomeButton {
        font-size: 3rem;
        margin-left: 0
      }
      .smallSlash {
      font-size: 2.5rem
      }
      }
