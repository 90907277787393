.ModalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
  background: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
}

.ModalContainer {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  z-index: 175;
  width: 90%;
  top: 9.75rem;
  max-width: 170rem;
  padding: 1rem 4rem 3rem 4rem;
  border-radius: 20px;
  max-width: 150rem;
  height:auto
}

.ModalContainerScroll {
  overflow: scroll;
  height: 75vh;
  border-radius: 10px;
  width: 100%;
}
.ModalContainerScroll.no {
  overflow: auto;
  height: inherit;

}
.ModalContainer-button {
  height: 4rem;
  font-size: 2rem;
  background: none;
}
.fhMobileImg {
  width: 75%;
  border-radius:20px
}
.fhModal {
  width: 100%;
  border-radius: 20px;

}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.competitive_lg {
  /* display:block; */
  object-fit: cover;
height: auto;
width: 80%;
 border-radius: 20px;
  /* width: 100%;
  height: fit-content; */
}



/* ///// Media Queries ///// */

@media screen and (min-width: 1200px)  {
  .competitive_lg {
    width: 85%
  }
}


@media screen and (max-width: 1200px)  {
  .ModalContainer {
    width: 100%;
  }
  .competitive_lg {
    width: 85%
  }
}
@media screen and (min-width: 769px) and (max-width: 1030px) {
  .ModalContainer {
    padding: 1rem 3rem 3rem 3rem;
  }
  .competitive_lg,.fhMobileImg {
    width: 100%
  }
}

@media screen and (max-width: 768px)  {
  .ModalContainer {
    padding: 1rem 0 0 0
  }

  .ModalContainerScroll.no {
    margin-bottom: -2rem
  }
  .competitive_lg {
    /* display:block; */
    /* object-fit: cover; */
    border-radius: 10px;
    /* display: block;
    object-fit: cover; */
    height: auto;
    width: 100%;
}
.fhMobileImg {
  width: 100%
}
  }

