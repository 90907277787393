.PrototypeContainer {
  display: block;
  margin: auto;
  margin-bottom: 8rem;
  width: 85rem;


}
.PrototypeContainer.fh {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 9rem;
  width: 90rem;
}
.prototype {
  width: 35rem
}
.prototype.fh_desktop {
  width: 65rem
}
.prototype.fh_mobile {
  width: 19.75rem
}
.prototype_container_mobile{
  margin: 0 -4rem
}

.prototype_container_desktop {
  margin-bottom: 4rem
}
@media screen and (min-width: 769px) and (max-width: 1030px) {
  .PrototypeContainer {
    width: 100%
  }

  .PrototypeContainer.fh {
    margin-bottom: 6rem;
    width: 68rem;
}
  .prototype {
    width: 25rem;
}

  .prototype.fh_desktop {
    width: 50rem
  }
  .prototype.fh_mobile {
    width: 18rem
  }
}


@media screen and (max-width: 768px) {
  .PrototypeContainer, .PrototypeContainer.fh {
    width: 100%;
      flex-direction: column;
      align-items: center;
  }
  .prototype {
    width: 40%
  }
  .prototype_container_desktop, .prototype_container_mobile{
    width: 100%
  }
  .prototype.fh_desktop {
    width: 70%
  }
  .prototype.fh_mobile {
    width: 30%
  }
}

@media screen and (max-width: 479px)  {
  .PrototypeContainer {
    margin-bottom: 7rem
  }
  .prototype {
    width: 50%
  }

}
