.Container.studies {
margin: auto;
margin-top: 20rem;
background: #FAF9F8;
width: 100%;
overflow-x: hidden
}

 /* ///// Media Queries ///// */
 @media screen and (max-width: 768px)  {
  .Container.studies {
    width: 100%
  }
 }
