.ResearchContainer {
  width: 100%;
  background: #231F20;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 6.5rem
}

.ResearchContainer.ssc {
  padding-bottom: 5rem
}
.ResearchContainer.bottom {
  margin-top: .5px;
  margin-bottom: 10rem;
  padding-bottom: 7.5rem
}
.competitive.persona {
  margin-top: -4rem;
}
.competitive.persona_fh {
  margin-top: -5rem;
}
.h5.research {
  margin-top: 2rem
}

@media screen and (min-width: 769px) and (max-width: 1030px) {
  .ResearchContainer.bottom {
    margin-bottom: 8rem;
    padding-bottom: 6.5rem;
  }
  .competitive.persona {
    margin-top: -5rem;
}
  }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .competitive.persona {
      margin-top: -4rem;
  }
  .competitive.persona_fh {
    margin-top: -4.5rem;
  }
  .ResearchContainer {
    padding-bottom: 4.5rem;
}
.ResearchContainer.bottom {
  padding-bottom: 8.5rem;
  margin-bottom: 7rem
}
.ResearchContainer.bottom.fh {
  padding-bottom: 5.5rem;
  margin-bottom: 7rem
}
    }

    @media screen and (max-width: 479px) {
      .ResearchContainer, .ResearchContainer.ssc, .ResearchContainer.bottom  {
padding-bottom: 3.5rem
      }
      .ResearchContainer.bottom {
        margin-bottom: 6.5rem
      }
      .competitive.persona_fh {
        margin-top: -4rem
      }
    }

