/* General reset for the page */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Main container for the carousel */
.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem
}

.carousel-container.fh{
  margin-top: -4rem

}

.prev-button {
background:  #231F20;
margin-right: -3rem

}
.prev-button.mobile {
  margin-right: -7rem

  }
  .next-button.mobile {
    margin-left: -7.5rem

    }
 .next-button {
  background:  #231F20;
  margin-left: -3rem

  }


img.carousel-image {
  width: 95rem
}

img.carousel-image.mobile {
  width: 65rem
}

.arrow {
  width: 4rem;
  position: relative;
  z-index: 50;
  background-color:  #231F20;
  margin-bottom:14rem
}

/* ///// Media Queries ///// */
@media screen and (min-width: 769px) and (max-width: 1030px) {
img.carousel-image.fh {
  width: 100%
}
img.carousel-image.mobile {
  width: 60%
}
}

@media screen and (max-width:768px) {
  .next-button {
    margin-left: -1rem
    }

    .prev-button {
      margin-right: -1rem
      }
      img.carousel-image {
        width: 100%
      }
      img.carousel-image.mobile {
        width: 70%
      }

}
@media screen and  (max-width: 479px) {

img.carousel-image.mobile {
  width:80%
}
.prev-button, .next-button {
margin-bottom: -10rem;
}
.prev-button {
  margin-left: -2rem
}
.next-button {
  margin-right: -2rem
}

}
