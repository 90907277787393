.UxButton {
  font-size: 5.5rem;
  text-transform: lowercase;
  font-weight:600;
  letter-spacing: -.03em;
  line-height: 1.1;
  margin-left: 4.3rem;
  margin-left: 0;
  color: #000;
}
.BottomContainer {
  margin: auto;
  margin-top: 6rem;
  margin-bottom: 6rem;
  display: flex
  ;
      flex-direction: column;
      justify-content: center;
      align-items: center;

}
.UxTopContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.UxBottomContainer {
  margin-top: 6rem
}
/* ///// Media Queries ///// */

  @media screen and (max-width: 1030px) {

  .BottomContainer.ux {
    width: 43.5rem;
}
.BottomContainer{
  width: 90%;
  margin-top:4rem
}
.BottomContainer.dev, .BottomContainer.print{
  width: 90%;
  margin-top:4rem
}
.UxBottomContainer {
  margin-top: 0
}
    .UxButton {
      font-size: 4.75rem
    }

    .UxTopContainer {

      flex-direction: column;
      align-items: center;
    }
  }

  @media screen and (min-width: 480px) and (max-width: 768px) {
    .BottomContainer.ux {
      width: 43.5rem;
  }
  .UxButton {
    font-size: 4.1rem;
}
}

@media screen and (max-width:479px) {
  .BottomContainer.ux {
    width: 30rem;
    margin-top: 5rem;
}
.UxButton {
  font-size: 3rem;
}
}
