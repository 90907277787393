.main_type.co {
  font-size: 18rem;
}
.print_text {
  font-size: 4.25rem;
  text-transform: lowercase;
  font-weight:600;
  letter-spacing: -.03em;
  line-height: 1.1;
  margin-left: 4.3rem;
  color: rgba(0, 0, 0, 0.5);
  background-color: inherit;
  text-transform: lowercase;
}

.Footer.editorial {
  margin-top: 4.5rem
}



 /* ///// Media Queries ///// */


 @media screen and (max-width: 768px) {
  .Footer.editorial {
    margin-top: 3.5rem
  }

}

@media screen and (max-width: 479px) {
  .BottomContainer.print {
    margin-top: 3.5rem;
    margin-bottom:6rem
  }

}

