.Footer {
  margin-top:6rem
}



/* ///// Media Queries ///// */

@media screen and (max-width: 768px) {
  .Footer {
    margin-top:4rem
  }
}
